// import Middleware from '../../middleware'
// const OUT_OF_APP_ROUTES = [
//   '/auth/sign-in/check-phone',
//   '/auth/sign-in/password',
//   '/auth/sign-up/email',
//   '/auth/sign-up/password',
//   '/auth/sign-up/phr',
//   '/auth/sign-up/otp',
//   '/auth/forgot-password/password',
//   '/auth/forgot-password/otp',
//   '/auth/error'
// ]
// const LAYOUT_OUT = { name: 'layout-out', appear: true }
// const LAYOUT_IN = { name: 'layout-in', appear: true }
// Middleware.transition = ({ app }) => {
//   const { router } = app
//   router.beforeResolve((to, from, next) => {
//     if (OUT_OF_APP_ROUTES.includes(to.path)) {
//       from.meta.layoutTransition = LAYOUT_OUT
//       to.meta.layoutTransition = LAYOUT_OUT
//     } else {
//       from.meta.layoutTransition = LAYOUT_IN
//       to.meta.layoutTransition = LAYOUT_IN
//     }
//     next()
//   })
// }
