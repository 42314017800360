"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import Vue from 'vue'
const apm_rum_1 = require("@elastic/apm-rum");
const composition_api_1 = require("@nuxtjs/composition-api");
exports.default = (0, composition_api_1.defineNuxtPlugin)(({ req, $config, store }, inject) => {
    // REGISTER PLUGIN
    const host = (req ? req.headers.host : window.location.host).replace(/\.|:/g, '-');
    const defaultConfig = {
        // https://www.elastic.co/guide/en/apm/agent/rum-js/current/configuration.html#server-url
        serviceName: host,
        serverUrl: 'https://apm.mhealthvn.com',
        active: true,
        instrument: true,
        distributedTracing: true,
        distributedTracingOrigins: [/wellcare/, /mhealthvn/]
        // ignoreTransactions: [/statscollector/]
    };
    const apm = (0, apm_rum_1.init)({
        ...defaultConfig,
        ...$config.apm
    });
    apm.observe('transaction:start', function (_transaction) {
        var _a;
        // FILTERING TRANSACTIONS
        // SET USER CONTEXT
        const storeUser = (_a = store.state.authen) === null || _a === void 0 ? void 0 : _a.user;
        if (storeUser)
            apm.setUserContext({
                id: storeUser._id,
                email: storeUser.email,
                username: storeUser.username
            });
    });
    inject('apm', apm);
});
