import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { usePreferences } from '@wellcare/vue-component';
export default defineNuxtMiddleware(async ({ route, redirect }) => {
    const { query } = route;
    const { get } = usePreferences();
    const refreshToken = await get({ key: 'refreshToken' });
    const redirectUrl = query === null || query === void 0 ? void 0 : query.redirect;
    if (redirectUrl && refreshToken.value) {
        const url = atob(redirectUrl);
        redirect(url, {
            refreshToken: refreshToken.value
        });
    }
});
