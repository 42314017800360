import { Preferences } from '@capacitor/preferences';
import useCookie from '@wellcare/vue-component/compositions/use-cookie';
import Middleware from '../../middleware';
Middleware.exchangeToken = async ({ route, $axios, redirect, store }) => {
    var _a, _b, _c;
    if (!process.client)
        return;
    const { commit } = store;
    const { query } = route;
    const { set } = Preferences;
    const exchangeToken = query === null || query === void 0 ? void 0 : query.access_token;
    const baseUrl = new URL(location.href);
    if (exchangeToken) {
        try {
            const res = await $axios.post('/identity/login', {
                exchangeToken
            });
            if (((_a = res.data) === null || _a === void 0 ? void 0 : _a.code) === 200) {
                const user = (_b = res.data) === null || _b === void 0 ? void 0 : _b.results;
                const { accessToken, refreshToken } = user;
                // Update the authentication state in the store
                commit('authen/SET_USER', user);
                commit('authen/SET_TOKEN', accessToken);
                $axios.setHeader('token', accessToken);
                useCookie().setCookie('token', accessToken);
                // Set the token and user data in preferences
                await Preferences.set({ key: 'token', value: accessToken });
                await Preferences.set({ key: 'refreshToken', value: refreshToken });
                await Preferences.set({
                    key: 'user',
                    value: JSON.stringify({
                        _id: user === null || user === void 0 ? void 0 : user._id,
                        phone: user === null || user === void 0 ? void 0 : user.phone,
                        countryCode: user === null || user === void 0 ? void 0 : user.countryCode,
                        avatar: user === null || user === void 0 ? void 0 : user.avatar
                    })
                });
                redirect(baseUrl.pathname);
            }
            else if (((_c = res.data) === null || _c === void 0 ? void 0 : _c.code) === 401) {
                await set({ key: 'exchangeToken', value: exchangeToken });
                redirect('/auth/sign-in/check-phone');
            }
        }
        catch (e) {
            console.error(e);
        }
    }
};
