import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    layout: 'default',
    props: {
        error: {
            type: Object,
            default: () => { }
        }
    }
});
