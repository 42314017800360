import { getterTree, mutationTree, actionTree } from 'typed-vuex';
import { getField, updateField } from 'vuex-composition-map-fields';
export const strict = false;
export const namespaced = true;
export const state = () => ({
    registerAvatar: {
        userFile: null,
        relationshipFile: null
    },
    checkPhone: {},
    forgotPassword: '',
    isOpenPrivacy: false,
    isOpenSupport: false,
    isDisableSubmit: false,
    isDisableSubmitForCountryCode: false
});
export const getters = getterTree(state, {
    getField,
    GET_CHECKPHONE(state) {
        return state.checkPhone;
    }
});
export const mutations = mutationTree(state, {
    updateField,
    TOGGLE_OPEN_PRIVACY(state) {
        state.isOpenPrivacy = !state.isOpenPrivacy;
    },
    TOGGLE_OPEN_SUPPORT(state) {
        state.isOpenSupport = !state.isOpenSupport;
    },
    SET_DISABLE_SUBMIT(state, val) {
        state.isDisableSubmit = val;
    },
    SET_DISABLE_SUBMIT_FOR_COUNTRY_CODE(state, val) {
        state.isDisableSubmitForCountryCode = val;
    }
});
export const actions = actionTree({ state, getters, mutations }, {});
