import { useContext } from '@nuxtjs/composition-api';
import duration from 'dayjs/plugin/duration';
export var Common;
(function (Common) {
    Common[Common["today"] = 0] = "today";
    Common[Common["yesterday"] = 1] = "yesterday";
    Common[Common["thisWeek"] = 2] = "thisWeek";
    Common[Common["thisMonth"] = 3] = "thisMonth";
})(Common || (Common = {}));
export const useDateRange = () => {
    const { app, i18n } = useContext();
    const types = [
        {
            text: i18n.t('last'),
            value: 0
        },
        {
            text: i18n.t('next'),
            value: 1
        }
    ];
    const units = [
        {
            text: i18n.t('seconds'),
            value: 'seconds'
        },
        {
            text: i18n.t('minutes'),
            value: 'minutes'
        },
        {
            text: i18n.t('hours'),
            value: 'hours'
        },
        {
            text: i18n.t('days'),
            value: 'days'
        },
        {
            text: i18n.t('weeks'),
            value: 'weeks'
        },
        {
            text: i18n.t('months'),
            value: 'months'
        },
        {
            text: i18n.t('years'),
            value: 'years'
        }
    ];
    const dayjs = app.$dayjs;
    app.$dayjs.extend(duration);
    const today = dayjs();
    const singleShortcuts = [
        { text: i18n.t('today'), onClick: () => new Date().toISOString() },
        {
            text: i18n.t('yesterday'),
            onClick: () => {
                const yesterday = dayjs().subtract(1, 'day').toISOString();
                return yesterday;
            }
        }
    ];
    const rangeShortcuts = [
        {
            text: i18n.t('today'),
            onClick: () => {
                const range = [
                    today.startOf('day').toISOString(),
                    today.endOf('day').toISOString()
                ];
                return range;
            }
        },
        {
            text: i18n.t('last 24 hours'),
            onClick: () => {
                const range = [
                    dayjs().subtract(1, 'day').toISOString(),
                    new Date().toISOString()
                ];
                return range;
            }
        },
        {
            text: i18n.t('this week'),
            onClick: () => {
                const range = [
                    today.startOf('week').toISOString(),
                    today.endOf('week').toISOString()
                ];
                return range;
            }
        },
        /*
        {
          text: i18n.t('last week'),
          onClick: () => [
            today.subtract(1, 'week').startOf('week').toISOString(),
            today.subtract(1, 'week').endOf('week').toISOString()
          ]
        },
        */
        {
            text: i18n.t('this month'),
            onClick: () => [
                today.startOf('month').toISOString(),
                today.endOf('month').toISOString()
            ]
        } /* ,

        {
          text: i18n.t('last month'),
          onClick: () => [
            today.subtract(1, 'month').startOf('month').toISOString(),
            today.subtract(1, 'month').endOf('month').toISOString()
          ]
        }
        */
    ];
    const getRange = (type, value, unit) => {
        const range = [];
        const duration = dayjs.duration(value, unit);
        if (type === 0) {
            range[1] = new Date().toISOString();
            range[0] = new Date(Date.now() - duration.$ms).toISOString();
        }
        else {
            range[0] = new Date().toISOString();
            range[1] = new Date(Date.now() + duration.$ms).toISOString();
        }
        return range;
    };
    return {
        singleShortcuts,
        rangeShortcuts,
        types,
        units,
        getRange
    };
};
