"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.mutations = exports.getters = exports.state = exports.namespaced = exports.strict = void 0;
const typed_vuex_1 = require("typed-vuex");
const vuex_composition_map_fields_1 = require("vuex-composition-map-fields");
exports.strict = false;
exports.namespaced = true;
const state = () => ({
    events: {}
});
exports.state = state;
exports.getters = (0, typed_vuex_1.getterTree)(exports.state, {
    getField: vuex_composition_map_fields_1.getField
});
exports.mutations = (0, typed_vuex_1.mutationTree)(exports.state, {
    updateField: vuex_composition_map_fields_1.updateField,
    REGISTER_EVENT(state, { eventName, callback }) {
        if (!state.events[eventName]) {
            state.events[eventName] = [];
        }
        state.events[eventName].push(callback);
    },
    UNREGISTER_EVENT(state, eventName) {
        delete state.events[eventName];
    },
    EMIT_EVENT(state, { eventName, payload }) {
        if (state.events[eventName]) {
            state.events[eventName].forEach((callback) => {
                callback(payload);
            });
        }
    }
});
exports.actions = (0, typed_vuex_1.actionTree)({ state: exports.state, getters: exports.getters, mutations: exports.mutations }, {
    registerEvent({ commit }, { eventName, callback }) {
        commit('REGISTER_EVENT', { eventName, callback });
    },
    unregisterEvent({ commit }, eventName) {
        commit('UNREGISTER_EVENT', eventName);
    },
    emitEvent({ commit }, { eventName, payload }) {
        commit('EMIT_EVENT', { eventName, payload });
    }
});
