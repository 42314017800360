import Middleware from '../../middleware';
Middleware.checkMembership = async (context) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { $axios, store, error } = context;
    const { commit, state } = store;
    const user = (_a = state === null || state === void 0 ? void 0 : state.authen) === null || _a === void 0 ? void 0 : _a.user;
    const token = (_b = state === null || state === void 0 ? void 0 : state.authen) === null || _b === void 0 ? void 0 : _b.token;
    if (user && token) {
        try {
            // get data membership from user data
            const isMember = user === null || user === void 0 ? void 0 : user.isMember;
            // if data membership is empty, call api get data
            if (token && !isMember) {
                const res = await $axios.get('/membership/member/subscriptions');
                // if user is already a member, set user data & end
                if (((_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.code) === 200) {
                    if ((_e = (_d = res.data) === null || _d === void 0 ? void 0 : _d.results) === null || _e === void 0 ? void 0 : _e.length) {
                        commit('authen/SET_MEMBERSHIP', {
                            isMember: true,
                            membership: (_f = res.data) === null || _f === void 0 ? void 0 : _f.results
                        });
                    }
                    else {
                        commit('authen/SET_MEMBERSHIP', {
                            isMember: false,
                            membership: (_g = res.data) === null || _g === void 0 ? void 0 : _g.results
                        });
                    }
                    return;
                }
                else {
                    commit('authen/SET_MEMBERSHIP', {
                        isMember: false,
                        membership: null
                    });
                }
            }
        }
        catch (e) {
            // if user is not member, set user data & recommend user view membership
            if (((_j = (_h = e === null || e === void 0 ? void 0 : e.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.code) === 404) {
                commit('authen/SET_MEMBERSHIP', {
                    isMember: false,
                    membership: null
                });
            }
            else
                error(e);
        }
    }
};
