// import { Context } from '@nuxt/types'
import { getterTree, mutationTree } from 'typed-vuex';
import { getField, updateField } from 'vuex-composition-map-fields';
export const strict = false;
export const namespaced = true;
export const state = () => ({ src: {} });
export const getters = getterTree(state, {
    getField
});
export const mutations = mutationTree(state, {
    updateField
});
