import { Preferences } from '@capacitor/preferences';
import Middleware from '../../middleware';
const getUserInfo = async ({ $axios, commit }) => {
    var _a;
    const res = await $axios.get('/user-management/user-info', {
        headers: {
            'X-Request-At': new Date().getTime()
        }
    });
    if (((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.code) === 200) {
        const { results: user } = res.data;
        // Update the authentication state in the store
        commit('authen/SET_USER', user);
        // Set the user data in preferences\
        await Preferences.set({
            key: 'user',
            value: JSON.stringify({
                _id: user === null || user === void 0 ? void 0 : user._id,
                phone: user === null || user === void 0 ? void 0 : user.phone,
                countryCode: user === null || user === void 0 ? void 0 : user.countryCode,
                avatar: user === null || user === void 0 ? void 0 : user.avatar,
                name: user === null || user === void 0 ? void 0 : user.name
            })
        });
    }
};
Middleware.authenApp = async ({ route, redirect, store, $axios, $config }) => {
    var _a, _b;
    if (!process.client)
        return;
    const config = $config['nuxt-module-account'];
    try {
        const paths = [
            '/auth/sign-in/check-phone',
            '/auth/sign-in/password',
            '/auth/sign-up/email',
            '/auth/sign-up/password',
            '/auth/sign-up/phr',
            '/auth/sign-up/otp',
            '/auth/forgot-password/password',
            '/auth/forgot-password/otp',
            '/auth/sign-out/landing',
            '/auth/sign-out/confirmidentifier',
            '/login'
        ];
        const { path, query } = route;
        const { state, commit } = store;
        // const token = await Preferences.get({ key: 'token' })
        const refreshToken = await Preferences.get({ key: 'refreshToken' });
        const user = await Preferences.get({ key: 'user' });
        const { phone, countryCode } = JSON.parse(user.value) || {
            phone: '',
            countryCode: ''
        };
        const storedToken = (_a = state === null || state === void 0 ? void 0 : state.authen) === null || _a === void 0 ? void 0 : _a.token;
        const storedUser = (_b = state === null || state === void 0 ? void 0 : state.authen) === null || _b === void 0 ? void 0 : _b.user;
        if (query === null || query === void 0 ? void 0 : query.redirect) {
            window.localStorage.removeItem('axios');
            await Preferences.set({
                key: 'redirect',
                value: query === null || query === void 0 ? void 0 : query.redirect
            });
        }
        if (paths.includes(path)) {
            // CHECK OTHER PATH
            if (['/login', '/auth/sign-in'].includes(path)) {
                redirect('/auth/sign-in/check-phone');
            }
            // REMOVE TOKEN
            if ([
                '/auth/sign-in/check-phone',
                '/auth/sign-out/landing',
                '/auth/sign-out/confirmidentifier'
            ].includes(path)) {
                await Preferences.remove({ key: 'token' });
                await Preferences.remove({ key: 'refreshToken' });
                await Preferences.remove({ key: 'signup' });
                await Preferences.remove({ key: 'forgotPassword' });
            }
            // CHECK QUERY
            if (![
                '/auth/sign-in/check-phone',
                '/auth/error',
                '/auth/sign-out/landing',
                '/auth/sign-out/confirmidentifier'
            ].includes(path) &&
                !Object.keys(query).length) {
                redirect('/auth/sign-in/check-phone');
            }
            // REMOVE USER STORE WHEN INTO SCREEN AUTHEN
            setTimeout(() => {
                commit('authen/REMOVE_USER');
            }, 5000);
            return;
        }
        // CHECK WHEN INTO HOMEPAGE
        if (!refreshToken.value && path !== '/auth/error') {
            await Preferences.remove({ key: 'token' });
            await Preferences.remove({ key: 'refreshToken' });
            window.localStorage.removeItem('axios');
            // LOGIC REDIRECT IN APP
            const location = btoa(window.location.href);
            Preferences.set({ key: 'redirectApp', value: location });
            if (phone && countryCode) {
                redirect({
                    path: '/auth/sign-in/password',
                    query: {
                        phoneNumber: phone,
                        countryCode
                    }
                });
            }
            else {
                redirect({
                    path: '/auth/sign-in/check-phone'
                });
            }
        }
        else if (!storedToken || !storedUser) {
            try {
                await getUserInfo({ $axios, commit });
            }
            catch (e) {
                console.error(e);
                await Preferences.remove({ key: 'token' });
                await Preferences.remove({ key: 'refreshToken' });
                redirect(config === null || config === void 0 ? void 0 : config.redirectErr);
            }
        }
    }
    catch (err) {
        console.error('Error in middleware [auth-wellcare]:', err);
        redirect(config === null || config === void 0 ? void 0 : config.redirectErr);
    }
};
