import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _33533c22 = () => interopDefault(import('../src/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _3cb1fb07 = () => interopDefault(import('../src/pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _6102c008 = () => interopDefault(import('../src/pages/admin/debug.vue' /* webpackChunkName: "pages/admin/debug" */))
const _6ddcf47e = () => interopDefault(import('../src/pages/admin/theme.vue' /* webpackChunkName: "pages/admin/theme" */))
const _f229d8b4 = () => interopDefault(import('../src/pages/admin/translation.vue' /* webpackChunkName: "pages/admin/translation" */))
const _1b320695 = () => interopDefault(import('../src/pages/auth/forgot-password/otp.vue' /* webpackChunkName: "pages/auth/forgot-password/otp" */))
const _63ad18a1 = () => interopDefault(import('../src/pages/auth/forgot-password/password.vue' /* webpackChunkName: "pages/auth/forgot-password/password" */))
const _789a9403 = () => interopDefault(import('../src/pages/auth/sign-in/check-phone.vue' /* webpackChunkName: "pages/auth/sign-in/check-phone" */))
const _268b7971 = () => interopDefault(import('../src/pages/auth/sign-in/password.vue' /* webpackChunkName: "pages/auth/sign-in/password" */))
const _5c407d94 = () => interopDefault(import('../src/pages/auth/sign-out/confirmidentifier.vue' /* webpackChunkName: "pages/auth/sign-out/confirmidentifier" */))
const _1d84f902 = () => interopDefault(import('../src/pages/auth/sign-out/landing.vue' /* webpackChunkName: "pages/auth/sign-out/landing" */))
const _619ea5a8 = () => interopDefault(import('../src/pages/auth/sign-up/email.vue' /* webpackChunkName: "pages/auth/sign-up/email" */))
const _1e688f3b = () => interopDefault(import('../src/pages/auth/sign-up/otp.vue' /* webpackChunkName: "pages/auth/sign-up/otp" */))
const _209007bb = () => interopDefault(import('../src/pages/auth/sign-up/password.vue' /* webpackChunkName: "pages/auth/sign-up/password" */))
const _3ef0d20a = () => interopDefault(import('../src/pages/auth/sign-up/phr.vue' /* webpackChunkName: "pages/auth/sign-up/phr" */))
const _7b5cdd3f = () => interopDefault(import('../src/pages/auth/content/_site/_slug.vue' /* webpackChunkName: "pages/auth/content/_site/_slug" */))
const _1bb8d387 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _33533c22,
    name: "account"
  }, {
    path: "/admin",
    component: _3cb1fb07,
    name: "admin"
  }, {
    path: "/admin/debug",
    component: _6102c008,
    name: "admin-debug"
  }, {
    path: "/admin/theme",
    component: _6ddcf47e,
    name: "admin-theme"
  }, {
    path: "/admin/translation",
    component: _f229d8b4,
    name: "admin-translation"
  }, {
    path: "/auth/forgot-password/otp",
    component: _1b320695,
    name: "auth-forgot-password-otp"
  }, {
    path: "/auth/forgot-password/password",
    component: _63ad18a1,
    name: "auth-forgot-password-password"
  }, {
    path: "/auth/sign-in/check-phone",
    component: _789a9403,
    name: "auth-sign-in-check-phone"
  }, {
    path: "/auth/sign-in/password",
    component: _268b7971,
    name: "auth-sign-in-password"
  }, {
    path: "/auth/sign-out/confirmidentifier",
    component: _5c407d94,
    name: "auth-sign-out-confirmidentifier"
  }, {
    path: "/auth/sign-out/landing",
    component: _1d84f902,
    name: "auth-sign-out-landing"
  }, {
    path: "/auth/sign-up/email",
    component: _619ea5a8,
    name: "auth-sign-up-email"
  }, {
    path: "/auth/sign-up/otp",
    component: _1e688f3b,
    name: "auth-sign-up-otp"
  }, {
    path: "/auth/sign-up/password",
    component: _209007bb,
    name: "auth-sign-up-password"
  }, {
    path: "/auth/sign-up/phr",
    component: _3ef0d20a,
    name: "auth-sign-up-phr"
  }, {
    path: "/auth/content/:site?/:slug?",
    component: _7b5cdd3f,
    name: "auth-content-site-slug"
  }, {
    path: "/",
    component: _1bb8d387,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
