"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// /* eslint-disable dot-notation */
const composition_api_1 = require("@nuxtjs/composition-api");
exports.default = (0, composition_api_1.defineNuxtPlugin)((ctx) => {
    const { $axios, app, $config } = ctx;
    const namespace = 'nuxt-module-data-layer';
    const baseURL = $config[namespace].baseURL;
    const XTenantId = $config[namespace].xTenantId;
    $axios.setBaseURL(baseURL);
    $axios.interceptors.request.use((config) => {
        var _a, _b;
        const token = (_b = (_a = config.headers) === null || _a === void 0 ? void 0 : _a.common) === null || _b === void 0 ? void 0 : _b.token;
        if (!token)
            $axios.setHeader('token', app.$cookies.get('token'));
        return config;
    }, (error) => Promise.reject(error));
    $axios.setHeader('x-identity-version', '2');
    if (XTenantId)
        $axios.setHeader('x-tenant-id', XTenantId);
    $axios.onError((error) => {
        if (error.response) {
            console.log(`[${error.response.config.method.toUpperCase()}] ${error.response.config.baseURL}${error.response.config.url} ${error.response.status}`, error.response.data);
        }
        else {
            console.error(error);
        }
    });
});
