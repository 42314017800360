"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.mutations = exports.getters = exports.state = exports.namespaced = exports.strict = void 0;
const typed_vuex_1 = require("typed-vuex");
const vuex_composition_map_fields_1 = require("vuex-composition-map-fields");
exports.strict = false;
exports.namespaced = true;
const state = () => ({
    database: {
        items: [],
        meta: []
    },
    socket: {}
});
exports.state = state;
exports.getters = (0, typed_vuex_1.getterTree)(exports.state, {
    getField: vuex_composition_map_fields_1.getField
});
exports.mutations = (0, typed_vuex_1.mutationTree)(exports.state, {
    updateField: vuex_composition_map_fields_1.updateField
});
exports.actions = (0, typed_vuex_1.actionTree)({ state: exports.state, getters: exports.getters, mutations: exports.mutations }, {});
