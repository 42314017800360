"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const composition_api_1 = require("@nuxtjs/composition-api");
const vue_component_1 = require("@wellcare/vue-component");
exports.default = (0, composition_api_1.defineNuxtPlugin)(async (ctx) => {
    if (!process.client)
        return; // Execute only on the client-side
    const { get } = (0, vue_component_1.usePreferences)();
    const namespace = 'nuxt-module-data-layer';
    const config = ctx.$config[namespace];
    const baseURL = config === null || config === void 0 ? void 0 : config.baseURL;
    let isRefreshing = false;
    let interval = null;
    let durationInMilliseconds = 10000; // 10 minutes default
    // Function to handle refreshing the access token
    const handleRefreshToken = async () => {
        var _a, _b, _c, _d;
        // Get the local access token from preferences
        const localToken = await get({ key: 'token' });
        const axios = ctx.$axios || ctx.app.$axios;
        if (localToken.value) {
            // Decode the JWT and get the expiration time
            const { exp, iat } = (0, vue_component_1.useJwt)(localToken.value);
            // Convert timestamps to Date objects
            const iatUnix = new Date(iat * 1000);
            const expUnix = new Date(exp * 1000);
            // Calculate the time difference and set the interval
            durationInMilliseconds = expUnix.getTime() - iatUnix.getTime() - 15000;
            clearInterval(interval);
            interval = setInterval(handleRefreshToken, durationInMilliseconds);
        }
        // Get the local refresh token from preferences
        const localRefreshToken = await get({ key: 'refreshToken' });
        // IMPORTANT !!!
        // Get the refresh token from the query parameters or local storage
        const queryRefreshToken = (_a = ctx.query) === null || _a === void 0 ? void 0 : _a.refreshToken;
        const refreshToken = queryRefreshToken && queryRefreshToken !== 'null'
            ? queryRefreshToken
            : localRefreshToken.value !== 'null'
                ? localRefreshToken.value
                : null;
        if (refreshToken) {
            try {
                const res = await axios.post('/identity/refresh-token', {
                    refreshToken
                });
                const results = (_b = res.data) === null || _b === void 0 ? void 0 : _b.results;
                const code = (_c = res.data) === null || _c === void 0 ? void 0 : _c.code;
                if (code === 200) {
                    const data = {
                        accessToken: results === null || results === void 0 ? void 0 : results.access_token,
                        refreshToken: results.refresh_token
                    };
                    ctx.store.dispatch('authen/setToken', { ctx, data });
                }
                else {
                    // Redirect to sign-in page if refresh fail
                    ctx.store.dispatch('authen/redirectToSignIn', { ctx });
                }
            }
            catch (e) {
                if (((_d = e === null || e === void 0 ? void 0 : e.response) === null || _d === void 0 ? void 0 : _d.status) === 499)
                    return;
                console.error(e);
                ctx.store.dispatch('authen/redirectToSignIn', { ctx });
            }
        }
    };
    // config axios SOS - Dangerous logic
    ctx === null || ctx === void 0 ? void 0 : ctx.$axios.setBaseURL(baseURL);
    ctx === null || ctx === void 0 ? void 0 : ctx.$axios.onError((error) => {
        if (error.response) {
            const statusCode = error.response.status;
            if (statusCode === 401 && !isRefreshing) {
                // Refresh token
                isRefreshing = true;
                clearInterval(interval);
                handleRefreshToken().then(() => {
                    isRefreshing = false;
                });
            }
        }
    });
    // Event close tab
    document.addEventListener('visibilitychange', async () => {
        if (document.hidden) {
            // Clear interval
            clearInterval(interval);
        }
        else {
            // Initial execution of handleRefreshToken
            await handleRefreshToken();
        }
    });
    // Initial execution of handleRefreshToken
    await handleRefreshToken();
    // Set the interval to periodically execute handleRefreshToken
    clearInterval(interval);
    interval = setInterval(handleRefreshToken, durationInMilliseconds);
});
