import Middleware from '../../middleware';
Middleware.generateToken = async ({ app, route, redirect, $config }) => {
    var _a;
    try {
        const { path, query } = route;
        const { $axios } = app;
        if (path === '/auth/sign-in/check-phone') {
            console.log('In login generation', $config);
            if ('id' in query && !('token' in query)) {
                // Execute when 'id' key exists but 'token' key is missing
                console.log("Has 'id' but missing 'token'");
                try {
                    const response = await $axios
                        .post('/identity/public/move-id/exchange-token', {
                        moveId: query.id
                    }, {
                        headers: {
                            'x-tenant-id': ((_a = $config === null || $config === void 0 ? void 0 : $config.account) === null || _a === void 0 ? void 0 : _a.xTenantId) || 'move-manulife'
                        }
                    })
                        .then((res) => res.data.results);
                    const { token } = response;
                    if (!token) {
                        console.error('[generate-token]Access token not found');
                        return;
                    }
                    console.log('generating access');
                    redirect({ query: { token } });
                }
                catch (err) {
                    console.error('[generate-token]Failed to execute generate:', err);
                }
            }
            else if ('id' in query && 'token' in query) {
                // Execute when both 'id' and 'token' keys exist
                console.log('[generate-token]Execute something else');
                return;
            }
            else {
                // Execute when 'id' key is missing
                console.log("[generate-token]Missing 'id' and 'token' keys");
                return;
            }
        }
    }
    catch (err) {
        console.error('[generate-token]Error in middleware:', err);
    }
};
