import Middleware from '../../middleware';
Middleware.locale = function (ctx) {
    var _a;
    if (!ctx.route.query.locale)
        return;
    const localeQuery = (_a = ctx.route.query.locale) === null || _a === void 0 ? void 0 : _a.toString();
    if (!ctx.app.i18n || !ctx.app.i18n.localeCodes)
        return;
    if (!ctx.app.i18n.localeCodes.includes(localeQuery))
        return;
    ctx.app.i18n.setLocale(localeQuery);
    ctx.app.i18n.setLocaleCookie(localeQuery);
};
export default Middleware;
