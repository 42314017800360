"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@vueuse/core");
function default_1({ $axios }) {
    const apis = (0, core_1.useStorage)('axios', []);
    const maxLength = 256;
    $axios.interceptors.request.use((config) => {
        const newConfig = { ...config };
        newConfig.metadata = { startTime: new Date() };
        return newConfig;
    }, (error) => Promise.reject(error));
    $axios.interceptors.response.use((response) => {
        var _a, _b, _c, _d;
        const newRes = { ...response };
        newRes.config.metadata.endTime = new Date();
        newRes.duration =
            ((_b = (_a = newRes === null || newRes === void 0 ? void 0 : newRes.config) === null || _a === void 0 ? void 0 : _a.metadata) === null || _b === void 0 ? void 0 : _b.endTime) - ((_d = (_c = newRes === null || newRes === void 0 ? void 0 : newRes.config) === null || _c === void 0 ? void 0 : _c.metadata) === null || _d === void 0 ? void 0 : _d.startTime);
        return newRes;
    }, (error) => {
        var _a, _b, _c, _d;
        const newError = { ...error };
        newError.config.metadata.endTime = new Date();
        newError.duration =
            ((_b = (_a = newError === null || newError === void 0 ? void 0 : newError.config) === null || _a === void 0 ? void 0 : _a.metadata) === null || _b === void 0 ? void 0 : _b.endTime) -
                ((_d = (_c = newError === null || newError === void 0 ? void 0 : newError.config) === null || _c === void 0 ? void 0 : _c.metadata) === null || _d === void 0 ? void 0 : _d.startTime);
        return Promise.reject(newError);
    });
    function unshift(item) {
        apis.value.unshift(item);
        if (apis.value.length > maxLength) {
            apis.value.splice(maxLength);
        }
    }
    $axios.onResponse((response) => {
        var _a, _b;
        unshift({
            url: (_a = response.config) === null || _a === void 0 ? void 0 : _a.url,
            time: new Date(),
            status: response.status,
            method: (_b = response.config) === null || _b === void 0 ? void 0 : _b.method,
            duration: response === null || response === void 0 ? void 0 : response.duration,
            data: response.data
        });
    });
    $axios.onError((err) => {
        var _a, _b, _c, _d;
        unshift({
            url: (_a = err.config) === null || _a === void 0 ? void 0 : _a.url,
            status: (_b = err.response) === null || _b === void 0 ? void 0 : _b.status,
            duration: (_c = err.response) === null || _c === void 0 ? void 0 : _c.duration,
            method: (_d = err.config) === null || _d === void 0 ? void 0 : _d.method,
            error: err.message
        });
    });
}
exports.default = default_1;
